import $ from 'jquery'
import jQuery from 'jquery'

window.onpopstate = function(event) {
    if (event.state.page != undefined){
        loadPage(event.state.page,'instant',false);
    }
};



function helpBlock(text,duration=30000){
    loadPage('helpblock','overlay',false);
    $('#helptext').html(text);
    window.setTimeout(hideHelpbox, duration);
}
window.helpBlock = helpBlock;

function pageTransition(page,transition){
    switch(transition) {
        case 'overlay':
            page.removeClass('inactive').addClass('active');
            page.focus();
            $('body').removeClass('loading');
            break;
        case 'instant':
        default:
                $('.container.active').removeClass('active').removeClass('popup').addClass('inactive');
                page.removeClass('inactive').addClass('active')
                $('body').removeClass('loading');

    }

}

function loadPage(page,transition='instant',update_history=true){

    $('body').addClass('loading');
    if ($('.container.'+page).length){
        var newpage = $('.container.'+page);
        pageTransition(newpage,transition);
        if (update_history){
            const state = { 'page': page, 'transition': transition }
            history.pushState(state, '','#'+page);
        }
    }
    else {
        $.get('/pages/'+page+'.inc',function(data,status){

            var newpage = $('<div>').addClass('container').addClass(page).html(data);
            $('body').append(newpage)
            pageTransition(newpage,transition);
            if (update_history){
                const state = { 'page': page,'transition': transition }
                history.pushState(state, '','#'+page);
            }
        });
    }

}

function hideHelpbox(){
    $('.container.helpblock').removeClass('active').addClass('inactive');
}

window.loadPage = loadPage;
$(document).ready(function(){


    $(document).on('click','.container.helpblock *',function(e){
         hideHelpbox();
    });


    $(document).on('click','button',function(e){
        if ($(this).data('page')){
            e.preventDefault();
            var update_history = ! $(this).data('nohistory');
            loadPage($(this).data('page'),'instant',update_history);
            if ($(this).data('page') == 'game'){
                helpBlock('Use the cursor keys to drive the robot.',30000);
            }
        }
    });

    loadPage('intro');

});